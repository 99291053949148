import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { HeaderSimple } from '../headers';
import SubscribeImage from '../images/SubscribeImage';

export default class IndexPage extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    name: null,
    email: null,
  };

  _handleChange = e => {
    // eslint-disable-next-line no-console
    console.log({
      [`${e.target.name}`]: e.target.value,
    });
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  _handleSubmit = e => {
    e.preventDefault();

    console.log('submit', this.state);

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`);

        if (result !== 'success') {
          throw msg;
        }
        alert(msg);
      })
      .catch(err => {
        console.log('err', err);
        alert(err);
      });
  };

  render() {
    return (
      <div className="container-fluid p-0">
        <HeaderSimple title="Get notified" />
        <div className="row bg-neutralDark p-0 align-content-center">
          <div className="col align-self-center">
            <div className="card bg-transparent border-0 text-center">
              <div className="card-body bg-transparent">
                <h2 className="subtitle col-md-6 p-4 mx-auto text-white text-center">Subscribe and keep updated</h2>
                <form onSubmit={this._handleSubmit}>
                  <div className="">
                    <div className="col">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control bg-transparent p-3 border-bottom border-left-0 border-right-0 border-top-0 rounded-0 text-center"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          onChange={this._handleChange}
                          placeholder="you@yourwebsite.com"
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="col-auto">
                      <input
                        type="submit"
                        className="btn m-4 btn-outline-light text-center rounded-0"
                        onChange={this._handleChange}
                        value=" Sign me up &rarr;"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col p-0">
            <div className="card p-0 border-0 h-100 rounded-0 bg-dark text-white">
              <SubscribeImage />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export const query = graphql`
//   query {
//     fileName: file(relativePath: { eq: "hung.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 400, maxHeight: 250) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `
