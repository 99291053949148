import React from 'react';

export const HeaderMedium = ({title}) => {
  return (
    <div className="col-md-6 py-5 mx-auto">
      <h1 className="text-center text-muted text-muted p-4">
        Discover all the
        <span className="text-primary font-weight-bold"> latest </span>
        in
        <span className="text-neutralDark font-weight-bold"> {title}</span>
      </h1>
    </div>
  );
};

export const HeaderSimple = ({subTitle, title}) => {
  return (
    <div className="col-md-6 py-5 mx-auto">
      <h1 className="text-center text-muted text-muted pt-4">{title}</h1>
      <h2 className="text-center lead text-muted text-muted pb-4">{subTitle}</h2>
    </div>
  );
};

export const HeaderColor = ({primary, textFirst, textSecond, textThird}) => {
  return (
    <div className="py-5">
      <h1 className="text-center text-muted col-md-6 mx-auto text-muted p-4">
        {/* eslint-disable-next-line react/destructuring-assignment,react/prop-types */}
        {textFirst}
        <span className="text-primary font-weight-bold"> {primary} </span>
        {textSecond}
        <span className="text-neutralDark font-weight-bold">{textThird}</span>
      </h1>
    </div>
  );
};
